@font-face {
  font-family: "Maderon-Regular";
  src: url("./assets/fonts/Maderon-Regular.otf");
}

@font-face {
  font-family: "LucianoDisplay-Medium";
  src: url("./assets/fonts/LucianoDisplay-Medium.otf");
}
.scroller {
  max-width: 100%;
  background-color: #e7e5e4;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.scroller__inner img {
  object-fit: contain;
  height: 5rem;
}
.scroller[data-animated="true"] {
  overflow: hidden;
}
.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}
.scroller[data-speed="fast"] {
  --_animation-duration: 100s;
}
.scroller[data-speed="slow"] {
  --_animation-duration: 300s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
